<template>
<div class="wrapper">
  <topbar2></topbar2>
  <header2></header2>
  <div class="content-wrapper">
    <div class="content-header">
       <div class="container">
      <div class="row">    
              <div class="col-md-12 col-12">
                 <div class="card-header">
                <h4 class="card-title"> Pay Now </h4>
              </div>
              </div>            
            </div>   
            
      </div>      
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-4"></div>
          <div class="col-lg-4 connectedSortable">
            <div class="card">             
              <div class="card-body">
                <div class="tab-content p-0">
                  <div class="chart tab-pane active">
                     <label>Payment Date</label>
                    <input type="date" class="form-control" name="pickup_date" id="pickup_date" v-model="pickup_date"/>
                    <br>
                    <label>Amount</label>
                    <br>
                    <input type="text" class="form-control" name="amount" id="amount" v-model="amount"/>
                    <br>
                    <button v-on:click="submit()" class="btn btn-success">Submit</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <footer2></footer2>
</div>
</template>

<script>
import moment from 'moment'
import { defineComponent } from 'vue'
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'
import Header from './Header.vue'
import Footer from './Footer.vue'
import Topbar from './Topbar.vue'
import axios from "axios";
export default {
  extends:defineComponent,
    components:{
        "header2": Header,
        "footer2": Footer,
        "topbar2": Topbar,              
      },
  name: 'paynow',  
    data(){
        return {
            //id: this.$route.params.id,
            company_id:this.$store.state.auth.user.data[0].company_id,
            user_id: '1',
            invoice_id: this.$route.params.id,
            pickup_date: this.pickup_date,
            amount: this.amount,            
            form: {
                user_id: '1',
                invoice_id: this.$route.params.id,
                pickup_date: moment(new Date()).format('DD-MM-YYYY'),
                amount: ''                
            }
        }
    },
    methods: {
    showsuccessmsg(msgData){
       createToast(msgData,{
          type: 'success',position: 'top-right',showIcon: true,timeout: 3000
        })
     },
    showfailedmsg(msgData){
       createToast(msgData,{
          type: 'danger',position: 'top-right',showIcon: true,timeout: 3000
        })
     },
    submit(){
            axios.post('http://laundry.ezeehost.com:4909/ClientIspmateApi/updatepayment', 
            {
              user_id: this.user_id,
              invoice_id: this.invoice_id,
              pickup_date: this.pickup_date,
              amount: this.amount,
              company_id: this.company_id
            }, {
              user_id: '1',
              invoice_id: this.$route.params.id              
            })
            .then(response => {
                   this.showsuccessmsg(response.data.response); 
                   localStorage.setItem('payamount', response.data.data.amount)  
                   this.$router.push({path:'/paymentreceipt/'+response.data.data.invoice_id})                      
                   console.log(response.data);
        })
        .catch(e => {
              this.showfailedmsg('Something went wrong');       
              console.log(e);
        });
                
        }
    }
}
</script>
<style>
  @import '../assets/dist/css/custom.css';  
</style>